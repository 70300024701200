import $ from "jquery";
import fitVids from "fitvids";
// import squishMenu from "squishMenu";

import { calculateProductTable, addToBasket } from "./sg-cart";
import { getValues, calcFloor } from "./sg-calculator";

$(document).ready(function() {
  // squishMenu(".site-nav-wrapper");
  fitVids(".site-content-wrapper");

  calculateProductTable();

  $(".calculator-open").click(function() {
    $("#modal-window").slideDown();
  });

  $(".modal-close").click(function() {
    $("#modal-window").slideUp();
  });

  $("#add-button").click(function() {
    const values = getValues();
    addToBasket(values.rolls);
  });

  if ($("#calc-floorplan")) {
    // Calculator
    const values = getValues();
    calcFloor(values.calcUnits, values.boardL, values.boards);
  }

  // Recalculate the total when the unit of measurement is changed
  $("#calc-units").change(function() {
    const values = getValues();
    const calcUnits = $(this).val();
    console.log("Units changed to " + calcUnits);

    calcFloor(calcUnits, values.boardL, values.boards);
  });

  $("#calc-length").change(function() {
    const values = getValues();
    const boardL = $(this).val();
    console.log("Length changed to " + boardL);

    calcFloor(values.calcUnits, boardL, values.boards);
  });

  $("#calc-boards").change(function() {
    const values = getValues();
    const boards = $(this).val();
    //console.log("Boards changed to " + boards);

    calcFloor(values.calcUnits, values.boardL, boards);
  });

  // recalculate when the button is clicked
  $("#calc-button").click(function() {
    const values = getValues();
    calcFloor(values.calcUnits, values.boardL, values.boards);
  });

  $(".product-qty").change(calculateProductTable);
});
