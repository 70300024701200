import $ from "jquery";
import { roundToTwo } from "./sg-calculator";
// Does sums for the StopGap product table

function checkQuantityBoxes() {
  $(".product-row").each(function() {
    if ($(".product-qty", this).val() != 0) {
      $(".single_add_to_cart_button").attr("disabled", false);
      return false;
    } else {
      $(".single_add_to_cart_button").attr("disabled", true);
    }
  });
}

function calculateProductTable() {
  var mult = 0;
  // for each row:
  $(".product-row").each(function() {
    // get the values from this row:
    var $price = $(".price", this).text();
    var $qty = $(".product-qty", this).val();
    var $total = $price * 1 * ($qty * 1);
    $(".row-total", this).val(roundToTwo($total));
    mult += $total;
  });
  $(".grand-total").val(roundToTwo(mult));
  checkQuantityBoxes();
}

function addToBasket(rollsForty) {
  //Reset everything to zero
  $(".product-qty").each(function() {
    $(this).val(0);
  });

  $("#40mBrown").val(rollsForty);
  $("#modal-window").slideUp(function() {
    calculateProductTable();
  });
}

export { checkQuantityBoxes, calculateProductTable, addToBasket };
