// Calculate how many 40m rolls are required

import $ from "jquery";
import Snap from "snapsvg-cjs";

// Helper Functions
// Round a number to 2 decimal places
function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function getValues() {
  const boards = document.getElementById("calc-boards")
    ? document.getElementById("calc-boards").value
    : 0;
  const boardL = document.getElementById("calc-length")
    ? document.getElementById("calc-length").value
    : 0;
  const calcUnits = document.getElementById("calc-units")
    ? document.getElementById("calc-units").value
    : "metres";
  const lengthCM = totalLength(calcUnits, boardL, boards);
  const rolls = lengthToRolls(lengthCM);

  const values = {
    boards,
    boardL,
    calcUnits,
    lengthCM,
    rolls
  };

  return values;
}

function unitsToCM(calcUnits, boardL) {
  // Convert all units to centimetres
  let calcCentimetres = 100;
  switch (calcUnits) {
    case "mm":
      calcCentimetres = boardL / 10;
      break;

    case "cm":
      calcCentimetres = boardL;
      break;

    case "metres":
      calcCentimetres = boardL * 100;
      break;

    case "inches":
      calcCentimetres = boardL * 2.54;
      break;

    case "feet":
      calcCentimetres = boardL * 30.48;
      break;
  }

  return calcCentimetres;
}

function totalLength(calcUnits, boardL, boards) {
  var gaps = boards - 1;
  var calcCentimetres = unitsToCM(calcUnits, boardL);

  return Math.ceil(gaps * calcCentimetres);
}

// Calcuate the number of rolls needed

function lengthToRolls(lengthInCM) {
  var lengthInMetres = lengthInCM / 100;

  // Round up to the nearest 40m
  var rolls = Math.ceil(lengthInMetres / 40);

  return rolls;
}

function calcFloor(calcUnits, boardL, boards) {
  // Defaults
  var gapW = 0.3;
  var boardW = 14.5;
  var calcCentimetres = unitsToCM(calcUnits, boardL);
  var totalLengthCM = totalLength(calcUnits, boardL, boards);
  var rolls = lengthToRolls(totalLengthCM);
  var totalSize = gapW * totalLengthCM;

  paintTheFloor(gapW, boardW, boards, calcCentimetres);

  //Product calculations
  //number of metres required

  var roundedLength = Math.ceil(totalLengthCM / 100);
  var roundedSize = roundToTwo(Math.sqrt(totalSize / 10000));
  var messageLength =
    'For <span class="calculate-text">' +
    roundedLength +
    "m</span> of floorboard gaps ";
  var messageRolls = `you will need <span class="calculate-text">${rolls}&times; 40m</span> rolls of StopGap.`;
  var messageSize = `Total area of the gaps in your floor is around <span class="calculate-text"> ${roundedSize} m<sup>2</sup></span>.`;

  $(".calc-message-rolls").html(messageLength + messageRolls);
  $(".calc-message-size").html(messageSize);
}

// requires snap-svg
function paintTheFloor(gapW, boardW, boards, boardL) {
  var roomW = boards * (boardW + gapW) - gapW;
  var gap = gapW * boardL * (boards - 1);
  var hole = Math.sqrt(gap);

  // Create the SVG with room dimension as viewBox
  var room = "0 0 " + roomW + " " + boardL;
  var floorplan = Snap("#calc-floorplan");

  if (floorplan) {
    // empty the SVG
    floorplan.clear();

    floorplan.attr({
      viewBox: room,
      preserveAspectRatio: "xMinYMin"
    });

    // Skirting board
    var underFloor = floorplan.rect(0, 0, roomW, boardL);
    underFloor.attr({ fill: "black" });

    // empty group for boards
    var floorboards = floorplan.group();

    // make some boards
    for (var i = 0; i < boards; i++) {
      var offset = (boardW + gapW) * i;
      var board = floorboards.rect(offset, 0, boardW, boardL);
      board.attr({ fill: "wheat" });
    }

    // hole
    var h = floorplan.rect(
      roomW / 2 - hole / 2,
      boardL / 2 - hole / 2,
      hole,
      hole
    );
    h.attr({ fill: "black" });
  }
}

export {
  roundToTwo,
  getValues,
  unitsToCM,
  totalLength,
  lengthToRolls,
  calcFloor,
  paintTheFloor
};
